import {
  ArrowRightEndOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import {
  ControlledNavigationBar,
  NavigationBarLink,
  NavigationExpandCollapse,
  NavigationList,
} from "@mci/cross-platform-components";
import { cva, cx } from "cva";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useAnalytics } from "~/components/Analytics/useAnalytics";
import { SideNavigationListGroup } from "~/components/Layout/LayoutSideNavigation/SideNavigationGroup";
import { SideNavigationListItem } from "~/components/Layout/LayoutSideNavigation/SideNavigationListItem";
import { SideNavigationProductMenu } from "~/components/Layout/LayoutSideNavigation/SideNavigationProductMenu";
import { SideNavigationUserMenu } from "~/components/Layout/LayoutSideNavigation/SideNavigationUserMenu";
import {
  CONSUMER_TRENDS,
  ECONOMIC_TRENDS,
  HOME,
  NEWSLETTERS,
  POLITICAL_TRENDS,
  REPORTS,
  SEARCH,
  OUR_PRODUCTS,
  TOPICS,
  TRACKERS,
} from "~/components/Layout/LayoutSideNavigation/sideNavMenuData";
import { useSideNavigationAnalytics } from "~/components/Layout/LayoutSideNavigation/useSideNavigationAnalytics";
import { LoginWrapper } from "~/components/LoginButton/LoginWrapper";
import { useIsNavigationExpanded } from "~/components/PersistentState";
import { commonConfig } from "~/config/common-config";
import { useActiveBreakpoint } from "~/lib/client/useActiveBreakpoint";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { userHasMcIntelAccess } from "~/lib/shared/userHasMcAppAccess";
import { getButtonStyles } from "~/ui-library/shared-styles/shared-button-styles";

export type LayoutSideNavigationProps = {
  className?: string;
  navigationBarClassName?: string;
  showProductMenu?: boolean;
};

const SidenavContainerStyles = cva("", {
  variants: {
    isExpanded: {
      true: "w-full min-w-[275px] max-w-[275px]",
      false: "w-fit",
    },
  },
});

export const LayoutSideNavigation: React.FC<LayoutSideNavigationProps> = ({
  className,
  navigationBarClassName,
  showProductMenu,
}) => {
  const { analyticsEnhancedTrack } = useAnalytics();
  const trackNavItemClicked = useSideNavigationAnalytics();
  const { data: session, status } = useSession();
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const { isNavigationExpanded, setIsNavigationExpanded } =
    useIsNavigationExpanded();

  const isExpanded = getValueOrDefault(isNavigationExpanded, true);

  const isLargeScreen = useActiveBreakpoint("LARGE");

  const shouldShowProductMenu = getValueOrDefault(showProductMenu, true);

  useEffect(() => {
    if (!isLargeScreen) {
      setIsProductMenuOpen(false);
      setIsUserMenuOpen(false);
    }
  }, [isLargeScreen]);

  return (
    <div className={cx(SidenavContainerStyles({ isExpanded }), className)}>
      <ControlledNavigationBar
        className={navigationBarClassName}
        isExpanded={isLargeScreen ? isExpanded : true}
        setIsExpanded={setIsNavigationExpanded}
        expandCollapse={
          isLargeScreen && (
            <NavigationExpandCollapse
              onClick={() => {
                setIsNavigationExpanded(!isExpanded);
                analyticsEnhancedTrack("Navigation Expand Collapse Clicked", {
                  "Is Expanded": !isExpanded,
                });
              }}
            />
          )
        }
        listItems={
          <NavigationList>
            <SideNavigationListGroup items={[HOME, SEARCH]} />

            <SideNavigationListGroup
              items={[
                CONSUMER_TRENDS,
                ECONOMIC_TRENDS,
                POLITICAL_TRENDS,
                TOPICS,
              ]}
            />

            <SideNavigationListGroup items={[NEWSLETTERS, REPORTS, TRACKERS]} />

            {userHasMcIntelAccess(session?.team?.permissions) && (
              <NavigationBarLink
                as={Link}
                activeIcon={null}
                inactiveIcon={null}
                endAdornment={<ArrowTopRightOnSquareIcon />}
                href={commonConfig.NEXT_PUBLIC_MCI_URL}
                target="_blank"
                label="Morning Consult Intelligence"
                className="lg:hidden"
              />
            )}

            {status === "authenticated" && <div className="flex-1"></div>}

            <SideNavigationListItem item={OUR_PRODUCTS} />

            <NavigationBarLink
              as={Link}
              href="/request-a-demo"
              className={cx(
                getButtonStyles({
                  size: "none",
                  variant: "primary",
                }),
                "mt-xs"
              )}
              inactiveIcon={<TvIcon aria-hidden="true" />}
              activeIcon={null}
              label={<>Request Demo</>}
              onClick={() => trackNavItemClicked("Request Demo")}
            />

            {status === "unauthenticated" && (
              <LoginWrapper as="li">
                <NavigationBarLink
                  as="button"
                  className={cx(
                    getButtonStyles({
                      size: "none",
                      variant: "subtle",
                    }),
                    "mt-xs"
                  )}
                  activeIcon={null}
                  inactiveIcon={
                    <ArrowRightEndOnRectangleIcon aria-hidden="true" />
                  }
                  label={<>Sign in</>}
                  onClick={() => trackNavItemClicked("Sign in")}
                />
              </LoginWrapper>
            )}
          </NavigationList>
        }
        productMenu={
          shouldShowProductMenu ? (
            <SideNavigationProductMenu
              isOpen={isProductMenuOpen}
              setIsOpen={setIsProductMenuOpen}
            />
          ) : (
            <div />
          )
        }
        userMenu={
          <SideNavigationUserMenu
            isOpen={isUserMenuOpen}
            setIsOpen={setIsUserMenuOpen}
          />
        }
      />
    </div>
  );
};
